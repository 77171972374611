import React from "react";

// import './LeftArrow.css';

const Facebook = () => (
  <svg fill="none" className="Facebook__root" viewBox="0 0 20 20">
    <path d="M20,10 C20,4.47714844 15.5228516,0 10,0 C4.47714844,0 0,4.47714844 0,10 C0,14.9912891 3.65685547,19.1283203 8.4375,19.8785156 L8.4375,12.890625 L5.8984375,12.890625 L5.8984375,10 L8.4375,10 L8.4375,7.796875 C8.4375,5.290625 9.93042969,3.90625 12.2146484,3.90625 C13.3087305,3.90625 14.453125,4.1015625 14.453125,4.1015625 L14.453125,6.5625 L13.1921484,6.5625 C11.9499023,6.5625 11.5625,7.33333984 11.5625,8.12416016 L11.5625,10 L14.3359375,10 L13.8925781,12.890625 L11.5625,12.890625 L11.5625,19.8785156 C16.3431445,19.1283203 20,14.9912891 20,10" fill="#81888F" />
    <path d="M13.8925781,12.890625 L14.3359375,10 L11.5625,10 L11.5625,8.12416016 C11.5625,7.33333984 11.9499023,6.5625 13.1921484,6.5625 L14.453125,6.5625 L14.453125,4.1015625 C14.453125,4.1015625 13.3087305,3.90625 12.2146484,3.90625 C9.93042969,3.90625 8.4375,5.290625 8.4375,7.796875 L8.4375,10 L5.8984375,10 L5.8984375,12.890625 L8.4375,12.890625 L8.4375,19.8785156 C8.94662109,19.9583984 9.4684375,20 10,20 C10.5315625,20 11.0533789,19.9583984 11.5625,19.8785156 L11.5625,12.890625 L13.8925781,12.890625" fill="#FFFFFE" />
  </svg>
);

export default Facebook;
